// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-hibas-jatek-jsx": () => import("./../../../src/pages/hibas-jatek.jsx" /* webpackChunkName: "component---src-pages-hibas-jatek-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-jatek-jsx": () => import("./../../../src/pages/jatek.jsx" /* webpackChunkName: "component---src-pages-jatek-jsx" */)
}

