import './src/css/global.css'
import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import {FunctionComponent} from 'react'

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_API_KEY)


export const wrapRootElement = ({ element }: {element: HTMLElement}): FunctionComponent => {
  return (
    <Elements stripe={stripePromise}>
      {element}
    </Elements>
  )
}