module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mokajándék","short_name":"Mokajándék","start_url":"/","background_color":"#f7f0eb","theme_color":"#f9c80e","display":"standalone","icons":[{"src":"./icon/android-icon-36x36.png","sizes":"36x36","type":"image/png"},{"src":"./icon/android-icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"./icon/android-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"./icon/android-icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"./icon/android-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"./icon/android-icon-192x192.png","sizes":"192x192","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-DH8BSE4MZ1"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"2767650293497963"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
